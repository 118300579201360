@import "@styles/common";

.procedure-status {
  @include flex();
  padding: 2px 14px;
  gap: 10px;

  &.Published {
    background-color: $table-status-published-bg-color;
    border-radius: 30px;
    color: $table-status-published-color;
  }

  &.Unpublished {
    background-color: $table-status-unpublished-bg-color;
    border-radius: 30px;
    color: $table-status-unpublished-color;
  }
}
