.sortable-header {
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;

  .icon {
    width: 20px;
    height: 20px;
  }

  .sort-icon {
    position: relative;
    display: inline-block;
  }
}
