@import "@assets/styles/variables.scss";
@import "@styles/mixins.scss";

.main-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;

  .sidebar-logo {
    display: flex;
    justify-content: center;
    padding: 32px 0;

    .logo {
      background-image: url("/assets/images/general/logo-3.svg");
      width: 48px;
      height: 48px;
    }
  }

  .sidebar-logout-wrapper {
    margin: auto 0 0;
  }

  .sidebar-logout {
    border-top: 1px solid #66359a;
    padding: 24px 0;
    width: 100%;
  }

  .sidebar-open-schema {
    margin: auto 25px;
    margin-bottom: 20px;
  }

  .sidebar-logout-btn {
    @include flex(space-between, center);
    padding: 0;
    margin: auto;
    color: $navbar-item-color;

    &:hover {
      color: $navbar-item-active-color;
      background-color: transparent;

      path {
        stroke: $navbar-item-active-color;
      }
    }
  }
}
