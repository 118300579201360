@import "@styles/common";

.user-form {
  padding: 32px;
  .error-message {
    margin: -15px 0 15px 0;
  }
  label {
    font-weight: 400;
    color: $modal-font-color-secondary;
    opacity: 50%;
    font-size: $font-size-small;
    line-height: 16px;
    margin-bottom: 14px;
  }
  .text-input-group {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 16px;
  }
  .user-select-input {
    border-bottom: 2px solid $modal-input-border-color;
    margin-bottom: 24px;

    .rs-picker-default {
      width: 100%;
      $input-padding: 10px;

      .rs-btn {
        height: $input-height;
        border-radius: $input-radius;
        padding-top: $input-padding;
        padding-bottom: $input-padding;

        .rs-picker-toggle-clean,
        .rs-picker-toggle-caret {
          top: $input-padding;
        }
      }
    }

    .rs-picker-toggle-value {
      color: $modal-font-color-secondary !important;
      font-size: $font-size-normal;
    }

    .rs-picker-default .rs-btn,
    .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
      background-color: $modal-input-background-color !important;
      border: none;
      border-radius: 0;
      justify-content: flex-start;
    }
    .rs-btn-close {
      display: none;
    }
  }
  .teams-body {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 23px;
  }
  .teams-header {
    font-weight: 400;
    color: $modal-font-color-secondary;
    opacity: 50%;
    font-size: $font-size-small;
    margin-bottom: 14px;
  }
  .team-radio {
    cursor: pointer;
    accent-color: $accent;
  }
  .team-label {
    margin-left: 3px;
    opacity: 1;
    margin-bottom: 8px;
    .team-label-text {
      margin-left: 8px;
      font-weight: 400;
      line-height: 22px;
      font-size: $font-size-normal;
    }
  }
  .user-date-input-wrapper {
    margin-bottom: 24px;
    .date-input {
      font-weight: 400;
      font-size: $font-size-normal;
      line-height: 22px;
      background-color: $modal-input-background-color;
      padding: 11.5px 10px;
      color: $modal-font-color-primary;
      border-radius: 0;
      border-bottom: 2px solid $modal-input-border-color;
      &:focus-visible,
      &:focus {
        appearance: none;
        outline: none;
        box-shadow: 0 0 0 0 white;
        border-color: $modal-input-border-color;
      }
    }
    .error-message {
      margin: 5px 0 15px 0;
    }
  }
  .user-form-footer {
    @include flex(flex-end);
    font-size: $font-size-header;
    font-weight: bold;
    color: $main-color;
  }
}
