@import "@styles/common";

.back-btn {
  margin-left: -10px;
  background-color: white;
  padding: 0;
  font-size: $font-size-normal;
  font-weight: $font-weight-bold;
  line-height: 22px;
  margin-bottom: 32px;
  div {
    @include flex(space-between, center);
    color: $details-dark-font-color;
  }
}
