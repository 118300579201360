@import "@styles/common";

.recognition-details-container-wrapper {
  padding: 32px;
  @include flex(flex-start, flex-start, column);
  .close-icon {
    @include flex(flex-end);
    width: 100%;
    .icon {
      margin-bottom: -20px;
      width: 24px;
      height: 24px;
    }
  }
  .recognition-details-icon {
    width: 96px;
    height: 96px;
    background-color: #3a2081;
    border-radius: 64px 0 64px 64px;
    position: relative;
    @include flex();
    .core-value-icon {
      min-height: 61px;
      min-width: 84px;
      background-repeat: no-repeat;
    }
    .core-value-score {
      width: 38px;
      height: 21px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .recognition-details-title {
    margin-top: 24px;
    font-size: $font-size-subheader;
    font-weight: $font-weight-large;
    line-height: 130%;
    margin-bottom: 24px;
  }
  .recognition-details-description {
    font-size: $font-size-normal;
    line-height: 150%;
    color: $main-color2;
    max-width: 450px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
  }
  .recognition-details-created-info {
    margin-top: 24px;
    font-size: $font-size-subtitle;
    color: #7f7d85;
    line-height: 150%;
  }
}
