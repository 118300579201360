@import "@styles/common";

.scrollable-wrapper {
  padding: 0 20px 20px;
  overflow-y: auto;
  max-height: calc(60vh - 120px);
  @include customScrollbar();
}

.no-matches-found-wrapper {
  margin-top: 100px;
}
