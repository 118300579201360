@import "@styles/common";

.procedure-status {
  @include flex();
  padding: 2px 14px;
  gap: 10px;
  border-radius: 30px;
  background-color: $table-status-in-progress-bg-color;
  color: $table-status-in-progress-color;

  &.Completed {
    background-color: $table-status-completed-bg-color;
    color: $table-status-completed-color;
  }

  &.New {
    background-color: $table-status-new-bg-color;
    color: $table-status-new-color;
  }
  &.Reviewed {
    background-color: $table-status-reviewed-bg-color;
    color: $table-status-reviewed-color;
  }
}
