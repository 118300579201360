@import "@styles/common";

.recognition-form {
  padding: 32px;
  label {
    font-weight: 400;
    color: $modal-font-color-secondary;
    opacity: 50%;
    font-size: $font-size-small;
    line-height: 16px;
    margin-bottom: 14px;
  }

  .recognition-form-footer {
    @include flex(flex-end, center);
    height: 48px;
    .recognition__anonymous-label {
      cursor: pointer;
      margin-bottom: 0;
      margin-right: auto;
      .recognition__anonymous-checkbox {
        cursor: pointer;
        width: 16px;
        height: 16px;
        accent-color: $accent;
        margin-right: 8px;
      }
    }
    label {
      opacity: 100%;
    }
  }

  .recognition-form-content {
    height: 100%;
    overflow: auto;
    .recognition-core-value-input,
    .recognition-score-input,
    .recognition-teammate-select-input {
      border-bottom: 2px solid $modal-input-border-color;
      .rs-picker-toggle-value {
        color: $modal-font-color-secondary !important;
        font-size: $font-size-normal;
      }
      .rs-picker-default .rs-btn,
      .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
        background-color: $modal-input-background-color !important;
        border: none;
        border-radius: 0;
        justify-content: flex-start;
      }
    }
    .recognition-select-input-group {
      @include flex(space-between, flex-start);

      .recognition-core-value-input-wrapper {
        width: 75%;
      }
      .recognition-score-input-wrapper {
        width: 120px;
      }
      margin-bottom: 24px;
    }
    .description-textarea-wrapper {
      margin: 24px 0;
      .recognition-description-input {
        font-weight: 400;
        font-size: $font-size-normal;
        line-height: 24px;
        background-color: $modal-input-background-color;
        height: 80px;
        max-height: 80px;
        padding: 0 0 40px 10px;
        color: $modal-font-color-secondary;
        border-radius: 0;
        border-bottom: 2px solid $modal-input-border-color;
        &:focus-visible,
        &:focus {
          appearance: none;
          outline: none;
          box-shadow: 0 0 0 0 white;
        }
      }
    }
  }
}
