@import "@assets/styles/variables.scss";

.input {
  position: relative;
  .password {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    background-color: white;
    padding: 0 0 20px 0;
    color: $auth-font-color;
    border-radius: 0;
    border-bottom: 2px solid $auth-input-border-color;
    &:focus-visible,
    &:focus {
      appearance: none;
      outline: none;
      box-shadow: 0 0 0 0 white;
      border-bottom: 2px solid $auth-input-border-color;
    }
  }

  input {
    font-family: $global-font-family;
    height: $input-height;
    background: $input-bg;
    border-radius: $input-radius;
    width: 100%;
    border: none;
    padding: 0 10px;
    color: $main-text-color;
    &:focus-visible,
    &:focus {
      appearance: none;
      border-color: $main-text-color;
      outline: 0;
      box-shadow: 0 0 0 3px #3491e33f;

      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  .additional-symbol-wrapper {
    position: absolute;
    top: 38px;
    right: 14px;
    .additional-symbol {
      font-weight: 600;
      font-size: 15px;
      color: #7E8387;
    }
  }
}

.view-password {
  position: absolute;
  top: 40px;
  right: 14px;
  width: 20px;
}
