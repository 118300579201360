@import "../../mixins.scss";

.icon {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &.sort {
    @include backgroundImage("/assets/images/icons/sort/sort.svg");
  }

  &.sort-asc {
    @include backgroundImage("/assets/images/icons/sort/sort-asc.svg");
  }

  &.sort-desc {
    @include backgroundImage("/assets/images/icons/sort/sort-desc.svg");
  }

  &.clear {
    @include backgroundImage("/assets/images/icons/general/clear.svg");
  }

  &.white-clear {
    @include backgroundImage("/assets/images/icons/general/white-cancel.svg");
  }

  &.find {
    @include backgroundImage("/assets/images/icons/general/find.svg");
  }

  &.eye {
    @include backgroundImage("/assets/images/icons/general/eye.svg");
  }

  &.eye-close {
    @include backgroundImage("/assets/images/icons/general/eye-close.svg");
  }

  &.arrow-left {
    @include backgroundImage("/assets/images/icons/general/arrow-left.svg");
  }

  &.arrow-down {
    @include backgroundImage("/assets/images/icons/general/arrow-down.svg");
  }

  &.loupe {
    @include backgroundImage("/assets/images/icons/general/loupe.svg");
  }
  &.warn {
    @include backgroundImage("/assets/images/icons/general/warning.svg");
  }
  &.logout {
    @include backgroundImage("/assets/images/icons/navbar/logout.svg");
  }

  &.apply {
    @include backgroundImage("/assets/images/icons/general/apply.svg");
  }

  &.export {
    @include backgroundImage("/assets/images/icons/general/export.svg");
  }

  &.edit {
    @include backgroundImage("/assets/images/icons/general/edit.svg");
  }

  &.edit-blue {
    @include backgroundImage("/assets/images/icons/general/edit-blue.svg");
  }

  &.delete {
    @include backgroundImage("/assets/images/icons/general/delete.svg");
  }

  &.delete-red {
    @include backgroundImage("/assets/images/icons/general/delete-red.svg");
  }

  &.btn-close {
    @include backgroundImage("/assets/images/icons/general/btn-close.svg");
  }

  &.btn-open {
    @include backgroundImage("/assets/images/icons/general/btn-open.svg");
  }

  &.back {
    @include backgroundImage("/assets/images/icons/general/back.svg");
  }

  &.google {
    @include backgroundImage("/assets/images/icons/general/google.svg");
  }
  &.survey-list {
    @include backgroundImage("/assets/images/icons/general/survey-list.svg");
  }
  &.procedure-list {
    @include backgroundImage("/assets/images/icons/general/procedure-list.svg");
  }
  &.user {
    @include backgroundImage("/assets/images/icons/general/user.svg");
  }
  &.chevron-left {
    @include backgroundImage("/assets/images/icons/general/chevron-left.svg");
  }
  &.arrow-long {
    @include backgroundImage("/assets/images/icons/general/arrow-long.svg");
  }
  &.progress-success {
    @include backgroundImage("/assets/images/icons/general/progress-success.svg");
  }
  &.goals {
    @include backgroundImage("/assets/images/icons/navbar/goals.svg");
  }
  &.goals-empty {
    @include backgroundImage("/assets/images/icons/general/goals-empty.svg");
  }
  &.procedures-empty {
    @include backgroundImage("/assets/images/icons/general/procedures-empty.svg");
  }
  &.recognitions-empty {
    @include backgroundImage("/assets/images/icons/general/recognitions-empty.svg");
  }
  &.danger {
    @include backgroundImage("/assets/images/icons/general/danger.svg");
  }
  &.close {
    @include backgroundImage("/assets/images/icons/general/close.svg");
  }
  &.not-found {
    @include backgroundImage("/assets/images/icons/general/not-found.svg");
  }
  &.filter {
    @include backgroundImage("/assets/images/icons/filter/filter.svg");
  }
  &.project-filter {
    @include backgroundImage("/assets/images/icons/filter/project-filter.svg");
  }
  &.team-filter {
    @include backgroundImage("/assets/images/icons/filter/team-filter.svg");
  }
  &.arrow-forward {
    @include backgroundImage("/assets/images/icons/general/arrow-forward.svg");
  }
  &.finish-onboarding {
    @include backgroundImage("/assets/images/icons/general/finish-onboarding.svg");
  }
  &.core-value-without-score {
    @include backgroundImage("/assets/images/icons/coreValues/core-value-without-score.svg");
  }
  &.core-value-score-20 {
    @include backgroundImage("/assets/images/icons/coreValues/core-value-score-20.svg");
  }
  &.core-value-score-15 {
    @include backgroundImage("/assets/images/icons/coreValues/core-value-score-15.svg");
  }
  &.core-value-score-10 {
    @include backgroundImage("/assets/images/icons/coreValues/core-value-score-10.svg");
  }
  &.core-value-score-5 {
    @include backgroundImage("/assets/images/icons/coreValues/core-value-score-5.svg");
  }
}
