@import "@assets/styles/mixins.scss";
@import "@assets/styles/variables.scss";

.table-row {
  min-height: 60px;
  font-family: $global-font-family;
  font-size: $table-font-size;
  color: $table-row-color;
  background-color: white;

  @include flex(flex-start, stretch);

  &.table-row_disabled {
    opacity: 0.6;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $table-border-color;
  }

  &:first-child {
    margin-top: 40px;
  }

  &:nth-child(even) {
    background-color: white;
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  .table-row-cell {
    display: flex;
    flex-wrap: wrap;
    white-space: break-spaces;

    flex-grow: 1;
    width: 100%;
    min-height: 60px;
    padding: 20px 25px;
    overflow: hidden;
    word-break: break-word;
    line-height: 150%;
    &.meeting-title {
      font-weight: 400 !important;
      color: $table-row-color !important;
      min-height: 120px;
      font-size: $font-size-subtitle !important;
    }
    &.meeting-created-by {
      color: $secondary !important;
      font-weight: $font-weight-bold !important;
      font-size: $font-size-normal;
    }

    &:first-child {
      color: $table-row-first-child-color;
      font-weight: $font-weight-bold;
      font-size: $font-size-normal;
      line-height: 21px;
    }

    @include flex(flex-start);

    &.cell-space-between {
      @include flex(space-between);
    }
  }

  .appear-click-block {
    display: flex;
    width: 50px;
    margin-left: 25px;
  }
}
