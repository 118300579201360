@import "@styles/common";

.comments-container {
  width: 512px;
  background: #EBE9F2;
  box-shadow: -3px 0px 21px rgba(49, 34, 92, 0.15);
  min-height: 100%;
  padding: 32px 24px;
  .comments-header {
    @include flex(space-between, center);
    .title {
      font-weight: 900;
      font-size: 20px;
      line-height: 40px;
      color: #373440;
    }
    .close-wrapper {
      width: 40px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 10px;
      @include flex(center, center);
      cursor: pointer;
      .icon {
        width: 14px;
        height: 14px;
        filter: brightness(0) saturate(100%) invert(14%) sepia(48%) saturate(3624%) hue-rotate(246deg) brightness(97%) contrast(102%);
      }
    }
  }
}
