@import "@assets/styles/variables.scss";

.radio-group {
  .radio-group-label {
    display: inline-block;
    font-size: unset;
    vertical-align: middle;
    &:not(:last-child) {
      margin-right: 1em;
    }
    color: $main-color;
  }

  .radio-group-input-inner {
    display: inline-block;
    margin-right: 0.35em;
    vertical-align: middle;
    position: relative;
    width: 18px;
    height: 18px;
    &::before {
      content: "";
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: $input-bg;
      border: 1px solid $light-gray-color;
    }
    &::after {
      content: "";
      position: absolute;
      display: none;
      margin: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $main-color;
    }
  }

  input {
    display: none;
    &:checked + .radio-group-input-inner::after {
      display: block;
    }
    &:disabled + .radio-group-input-inner::after {
      background: $gray-color;
      cursor: not-allowed;
    }
  }
}
