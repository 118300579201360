@import "@assets/styles/variables";
@import "@assets/styles/mixins";

.notification {
  display: flex;
  align-items: center;
  width: 360px;
  font-family: $global-font-family;
  background: $success-notification-color;
  border-radius: 8px;
  font-size: 14px;
  line-height: 24px;
  color: white;
  padding: 15px 20px;
  margin-bottom: 15px;

  &.error {
    background: $error-notification-color;
  }

  &.success {
    background: $success-notification-color;
  }

  .icon {
    width: auto !important;
  }
}

.notification-img {
  width: 36px;
  height: 30px;

  &.error {
    @include backgroundImage("../../../../../assets/images/error/failed.png");
    background-position: 0;
  }

  &.success {
    @include backgroundImage("../../../../../assets/images/error/success.png");
    background-position: 0;
  }
}

.notification-message {
  width: 100%;
  padding: 0 15px;
}

.icon {
  width: 20px;
  height: 20px;
  padding: 6px;
}
