@import "@styles/common";

.assign-procedure-form {
  max-height: 700px;
  padding: 32px;

  label {
    font-weight: 400;
    color: $modal-font-color-secondary;
    opacity: 50%;
    font-size: $font-size-small;
    line-height: 16px;
    margin-bottom: 14px;
  }

  .assign-procedure-form-footer {
    @include flex(flex-end);
    height: 48px;
    font-size: $font-size-header;
    font-weight: bold;
    color: $main-color;
    padding-bottom: 32px;
  }

  .assign-procedure-form-content {
    height: 100%;
    overflow: auto;

    .procedure-process-input-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 23px;

      .procedure-process-input {
        margin-top: 19px;
        border-bottom: 2px solid $modal-input-border-color;

        .rs-picker-toggle-value {
          color: $modal-font-color-secondary !important;
          font-size: $font-size-normal;
        }

        .rs-picker-default .rs-btn,
        .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
          background-color: $modal-input-background-color !important;
          border: none;
          border-radius: 0;
          justify-content: flex-start;
        }
        &.select-wrapper {
          width: 184px;
          max-width: 184px;
          overflow: hidden;
          .rs-picker-toggle-value {
            width: 148px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &.procedure-search-wrapper {
          margin-right: 24px;
          width: 70%;
          .search {
            min-width: 70%;
          }
        }
      }
    }

    .procedures-wrapper {
      margin-bottom: 23px;
      overflow: hidden;

      .no-procedures {
        min-height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .procedures-body {
        display: grid;
        max-width: 624px;
        min-height: 105px;
        grid-template-columns: repeat(3, 33%);
      }

      .procedures-header {
        font-weight: 400;
        color: $modal-font-color-secondary;
        opacity: 50%;
        font-size: $font-size-small;
        margin-bottom: 14px;
      }

      .procedure-checkbox {
        accent-color: $secondary;
        min-width: 16px;
        min-height: 16px;
      }

      .procedure-label {
        margin-left: 3px;
        opacity: 1;
        margin-bottom: 8px;
        border: none;

        .procedure-label-text {
          word-wrap: break-word;
          margin-left: 8px;
          font-weight: 400;
          line-height: 22px;
          font-size: $font-size-normal;
          overflow: hidden;
        }
      }
    }
  }
}
