.project-create-edit-container {
  .title-wrapper {
    padding: 32px 32px 0 32px;
    .title {
      font-size: 18px;
      line-height: 21px;
      color: #5c556f;
    }
    hr {
      margin-top: 15px;
      margin-bottom: -15px;
    }
  }
}
