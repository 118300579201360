@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.sidebar-container {
  height: 100%;
  width: 230px;
  background-color: $navbar-bg;
  box-shadow: $navbar-box-shadow;
  .sidebar-items {
    @include flex(flex-start, center, column);
    height: calc(100vh - 220px);
  }
}
