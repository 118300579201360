@import "@styles/common";

.no-matches-found {
  @include flex(center, center, column);
  height: 100%;
  min-height: 300px;
  margin: auto;
  .icon {
    width: 120px;
    height: 120px;
    cursor: default;
    background-size: contain;
  }
  .no-matches-label {
    margin-top: 30px;
    font-weight: 900;
    font-size: 22px;
    line-height: 29px;
    color: #7F7D85;
    opacity: 0.5;
  }
}
