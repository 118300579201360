@import "@styles/common";

.recognition-list-core-values-summary {
  @include flex(flex-start, flex-start, column);
  .core-values-summary-title {
    font-size: $font-size-subheader;
    font-weight: $font-weight-bold;
    color: $secondary;
    line-height: 32px;
    margin-bottom: 8px;
  }
  .core-values-summary-subtitle {
    font-size: $font-size-subtitle;
    color: $main-color2;
    line-height: 150%;
    margin-bottom: 24px;
    max-width: 75%;
  }
  .core-values-cards-section {
    @include flex(space-between, flex-start, row);
    width: 100%;
    margin-bottom: 38px;
    gap: 16px;
  }
}
