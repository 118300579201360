@import "@styles/common";

.core-value-card {
  &.selected {
    box-shadow: 0 7px 30px rgba(91, 46, 215, 0.73);

    .core-value-card-info {
      .value-title-section {
        color: $primary;
      }
    }
  }

  width: 240px;
  height: 144px;
  background: $secondary;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 8px 8px 8px 16px;
  cursor: pointer;
  @include flex(space-between, flex-start);
  .core-value-card-info {
    @include flex(flex-start, flex-start, column);
    .value-icon-section {
      .icon {
        min-height: 61px;
        min-width: 84px;
      }
      margin: 8px 0;
    }
    .value-title-section {
      font-size: 12px;
      font-weight: $font-weight-bold;
      color: white;
      padding-right: 5px;
      @media (max-width: 1500px) {
        font-size: $font-size-subtitle;
      }
      @media (max-width: 1400px) {
        font-size: $font-size-small;
      }
    }
    .value-score-section {
      font-size: $font-size-small;
      color: white;
    }
  }
  .core-value-progress-bar {
    width: 16px;
    height: 100%;
    background: #593aab;
    border-radius: 32px;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
    .core-value-progress-bar-inner {
      width: 100%;
      background: linear-gradient(180deg, #ff7be4 0%, #3d7fe3 100%);
      border-radius: 0 0 32px 32px;
    }
  }
}
