@import "@assets/styles/mixins.scss";
@import "@assets/styles/variables.scss";

.status-column {
  display: flex;
  align-items: center;

  .status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #F1AE49;

    &.Active {
      background-color: #57ba08;
    }

    &.Inactive, &.Closed {
      background-color: #ca4cb0;
    }

    &.Archived {
      background-color: #c8c3dc;
    }

    &.Completed {
      background-color: $table-status-completed-color;
    }
  }
}
