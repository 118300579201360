@import "@styles/common";

.detail-group {
  margin: 32px 0;
  .detail-status-toggle {
    @include flex(space-between);
  }
  .detail-privacy-toggle {
    @include flex(space-between);
  }
  .detail-status-badge {
    @include flex(flex-start);
  }
  .detail-item-label {
    font-size: $font-size-small;
    line-height: 16px;
    color: $details-color-secondary;
    margin-bottom: 8px;
  }
  .detail-item-name {
    font-size: $font-size-normal;
    line-height: 21px;
    margin-bottom: 8px;
    color: $details-items-color;
  }
}
