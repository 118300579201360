@import "@styles/common";

$finish-popup-font-color: radial-gradient(49.43% 90.62% at 77.16% 9.37%, #562081 6.48%, #3a2081 78.59%);
.finish-onboarding-container-wrapper {
  padding: 32px;
  @include flex(flex-start, flex-start, column);
  .finish-onboarding-container-title {
    @include flex();
    margin-bottom: 15px;
    width: 100%;
    font-size: 22px;
    font-weight: $font-weight-large;
    line-height: 130%;
    background: $finish-popup-font-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .finish-onboarding-container-score {
    margin-bottom: 10px;
    @include flex();
    width: 100%;
    font-size: 20px;
    font-weight: $font-weight-large;
    line-height: 130%;
    background: $finish-popup-font-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .finish-onboarding-container-icon-wrapper {
    @include flex();
    margin-bottom: 40px;
    margin-top: 15px;
    width: 100%;
    .icon {
      min-width: 240px;
      min-height: 240px;
    }
  }
  .button-wrapper {
    min-width: 100%;
    .finish-onboarding-container-button {
      width: 100%;
    }
  }
}
