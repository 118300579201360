@import "@styles/common";

.status-label {
  position: relative;
  @include flex(flex-start);
  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-top: 0;
    margin-right: 8px;
  }
  &.New {
    &::before {
      background-color: $accent;
    }
  }
  &.Completed {
    &::before {
      background-color: $primary;
    }
  }
  &.In-progress {
    &::before {
      background-color: $orange;
    }
  }
}
