@import "@styles/common";

.processes-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 24px;
  align-items: stretch;
  margin: 0 20px;
  .process-wrapper {
    height: 308px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    cursor: pointer;
    .process {
      margin: 40px;
      .image-wrapper {
        width: 42px;
        height: 42px;
        img {
          width: 42px;
          height: 42px;
        }
      }
      .title-wrapper {
        margin-top: 24px;
        font-weight: $font-weight-bold;
        .title {
          font-size: 20px;
          line-height: 32px;
          color: $secondary;
        }
        .counter {
          font-size: $font-size-subtitle;
          line-height: 24px;
          color: #373440;
        }
      }
      .button-wrapper {
        margin-top: 56px;
        .button {
          width: 44px;
          height: 44px;
          background: #EBE9F2;
          border-radius: 72px;
        }
      }
    }
  }
}
