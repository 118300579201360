@import "@styles/common";

.assign-survey-form {
  padding: 32px;
  label {
    font-weight: 400;
    color: $modal-font-color-secondary;
    opacity: 50%;
    font-size: $font-size-small;
    line-height: 16px;
    margin-bottom: 14px;
  }

  .assign-survey-form-footer {
    @include flex(flex-end);
    height: 48px;
    font-size: $font-size-header;
    font-weight: bold;
    color: $main-color;
  }

  .assign-survey-form-content {
    height: 100%;
    overflow: auto;
    .survey-input-wrapper {
      margin-bottom: 23px;
      .survey-input {
        margin-top: 19px;
        border-bottom: 2px solid $modal-input-border-color;
        .rs-picker-toggle-value {
          color: $modal-font-color-secondary !important;
          font-size: $font-size-normal;
        }
        .rs-picker-default .rs-btn,
        .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
          background-color: $modal-input-background-color !important;
          border: none;
          border-radius: 0;
          justify-content: flex-start;
        }
      }
    }
  }
}
