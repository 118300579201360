@import "@styles/common";

.recognition-overlay__creation-note-container {
  padding: 32px;
  @include flex(flex-start, center, column);

  .recognition-overlay__creation-note-title {
    @include flex(center, center, column);
    width: 100%;
    color: $secondary;
    margin-bottom: 24px;

    &__main {
      font-size: 22px;
      font-weight: $font-weight-large;
      line-height: 130%;
      margin-bottom: 12px;
    }

    &__sub {
      font-size: 20px;
      font-weight: $font-weight-bold;
      line-height: 130%;
    }
  }

  .recognition-overlay__creation-note-icon {
    width: 100%;
    height: 272px;

    .icon {
      cursor: default;
    }
  }

  .recognition-overlay__creation-note-comment {
    margin: 40px 0;
    color: $main-color2;
    font-size: $font-size-subtitle;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }

  .recognition-overlay__creation-note-button-wrapper {
    width: 100%;
  }
}
