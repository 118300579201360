@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.search {
  position: relative;
  min-height: 48px;
  font-size: 16px;
  line-height: 150%;
  font-family: $global-font-family;
  min-width: 460px;

  .input-search {
    background: linear-gradient(0deg, rgba(92, 85, 111, 0.06), rgba(92, 85, 111, 0.06)), #ffffff;
    color: $main-text-color;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 13px 40px 12px 40px;
    border: none;
    outline: none;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .search-icon-block {
    position: absolute;
    bottom: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &.find {
      top: 14px;
      left: 10px;
    }

    &.delete {
      right: 10px;
      cursor: pointer;
    }
  }

  .search-controls {
    @include flex(flex-start);

    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    padding: 10px;
  }
}
