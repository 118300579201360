.create-edit-meeting-container {
  .title-wrapper {
    padding: 32px 32px 0 32px;
    .title {
      font-size: 20px;
      line-height: 21px;
      color: #5c556f;
    }
    hr {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}
