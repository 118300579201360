@import "@styles/variables";

.goal-close-content-wrapper {
  margin: 0;
  .goal-close-heading {
    margin: 20px;
    font-weight: 600;
    font-size: 22px;
    color: $main-text-color;
    line-height: 30px;
    font-family: $global-font-family;
    font-style: normal;
  }
  .goal-close-content {
    margin: 10px 20px 20px 20px;
    font-family: $global-font-family;
    color: $main-text-color;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  .goal-close-actions {
    padding: 20px;
    text-align: right;
    background-color: $bg-color;
    .button-wrapper {
      display: inline-block;
      margin-left: 10px;
    }
  }
  .comment-wrapper {
    margin: 20px;
    position: relative;
    .comment-notice {
      margin-bottom: 5px;
      color: $main-text-color;
      font-size: 14px;
    }
    .comment-input {
      font-weight: 400;
      font-size: $font-size-normal;
      line-height: 21px;
      background-color: #EBE9F2;
      resize: none;
      height: 121px;
      padding: 10px;
      color: $modal-font-color-primary;
      border-radius: 0;
      border-bottom: 2px solid $modal-input-border-color;
      opacity: 0.8;
      &:focus-visible,
      &:focus {
        appearance: none;
        outline: none;
        box-shadow: 0 0 0 0 white;
      }
    }
    .counter {
      position: absolute;
      right: 20px;
      bottom: -13px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #9D9BA2;
    }
  }
}
