@import "@styles/common";

.tab-bar-nav {
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tab-bar-nav-left {
    .tab-nav-item {
      position: relative;
      padding: 0 20px;
      height: 50px;
      color: $secondary;
      font-weight: $font-weight-bold;
      font-size: 14px;
      font-style: normal;
      background: #fff;
      text-transform: uppercase;
      line-height: 32px;
      letter-spacing: 1.5px;
      &.active-tab {
        color: $primary;
        opacity: 1;
        font-weight: $font-weight-large;
      }
      &.active-tab::before {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 4px;
        background: $primary;
        content: "";
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
