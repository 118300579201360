@import "@styles/common";

.goal-form {
  padding: 32px;
  max-height: 95vh;
  .goal-form-content {
    padding: 4px;
    .input-wrapper {
      margin-bottom: 20px;
      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #373440;
        opacity: 0.5;
        margin-bottom: 10px;
      }
      .text-input, .date-input, .textarea-input {
        font-weight: 400;
        font-size: $font-size-normal;
        line-height: 22px;
        background-color: white;
        padding: 0 0 20px 0;
        color: $modal-font-color-primary;
        border-radius: 0;
        border-bottom: 2px solid $modal-input-border-color;
        &:focus-visible,
        &:focus {
          appearance: none;
          outline: none;
          box-shadow: 0 0 0 0 white;
          border-color: $modal-input-border-color;
        }
        &.number {
          padding: 0 0 10px 0;
        }
      }
      .date-input, .textarea-input {
        background-color: $modal-input-background-color;
        padding: 11.5px 10px;
      }
      .textarea-input {
        line-height: 24px;
        height: 80px;
        max-height: 80px;
      }
    }
    .goal-progress-input {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      
      input[type=number] {
        -moz-appearance: textfield;
      }

      .additional-symbol-wrapper {
        top: 30px;
        right: 10px;
      }
    }
  }
  .goal-form-footer {
    @include flex(flex-end, center);
    height: 48px;
    .accent {
      width: 192px;
    }
  }
}
