@import "@styles/common";

.goal-content-wrapper {
    display: flex;
    margin: 10px 24px 32px 24px;
    min-height: 400px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    .goal-details-wrapper {
      @include flex(space-between, initial, column);
      background: rgba(255, 255, 255, 0.1);
      width: 70%;
      padding: 40px;
      overflow-wrap: anywhere;
      .icons-wrapper {
        @include flex(space-between, center);
        margin-bottom: 16px;
        .item-icon {
          width: 40px;
          height: 40px;
          background: rgba(58, 32, 129, 0.1);
          border-radius: 8px;
          @include flex();
          .icon {
            cursor: default;
            width: 20px;
            height: 20px;
            filter: brightness(0) saturate(100%) invert(17%) sepia(46%) saturate(3079%) hue-rotate(242deg) brightness(89%) contrast(105%);
          }
        }
        .actions {
          display: flex;
          .icon {
            width: 20px;
            height: 20px;
            filter: brightness(0) saturate(100%) invert(17%) sepia(46%) saturate(3079%) hue-rotate(242deg) brightness(89%) contrast(105%);
            background-size: contain;
          }
          .icon:not(:last-child) {
            margin-right: 35px;
          }
        }
      }
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #000000;
      }
      .date {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #373440;
        margin-top: 5px;
        .date-expired {
          font-weight: 600;
          color: #F03232;
          display: flex;
          .icon {
            width: 24px;
            height: 24px;
            margin-right: 5px;
            cursor: default;
          }
        }
      }
      .description {
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        color: #000000;
        margin: 16px 0;
      }
      .status-wrapper {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #5C556F;
        margin: 16px 0;
      }
    }
    .goal-comments-wrapper {
      background: #EFEDF5;
      width: 30%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 40px;
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 40px;
        color: #373440;
      }
      .comments-textarea-wrapper {
        .comment-input {
          background-color: #EFEDF5;
        }
      }
      .actions-wrapper {
        margin-top: 17.5px;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #3A2081;
        @include flex(initial, center);
        .icon {
          width: 25px;
          height: 25px;
          margin-left: 5px;
          background-size: contain;
          &.right {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

