@import "@styles/mixins.scss";
@import "@styles/variables.scss";

.progress-label {
  @include flex();
  .progress-container-wrapper {
    position: relative;
    width: 90%;
    height: 8px;
    margin-right: 8px;
  }

  .progress-container {
    width: 100%;
    background-color: $table-header-bg;
  }

  .progress-container,
  .progress-thumb {
    position: absolute;
    left: 0;
    border-radius: 30px;
    height: 8px;
  }

   .progress-thumb {
    z-index: 2;
    background: linear-gradient(180deg, #C2ADFF 0%, #8973C7 100%);
   }

   .progress-value {
    word-break: keep-all;
   }
}
