@import "@styles/common";

.comments-textarea-wrapper {
  margin-top: 16px;
  margin-bottom: 10px;
  position: relative;
  .comment-input {
    font-weight: 400;
    font-size: $font-size-normal;
    line-height: 21px;
    background-color: #EBE9F2;
    resize: none;
    max-height: 121px;
    height: 42px;
    padding: 0 32px 19px 0;
    color: $modal-font-color-primary;
    border-radius: 0;
    border-bottom: 2px solid $modal-input-border-color;
    opacity: 0.8;
    &:focus-visible,
    &:focus {
      appearance: none;
      outline: none;
      box-shadow: 0 0 0 0 white;
    }
  }
  .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 10px;
    bottom: 15px;
  }
  .counter {
    position: absolute;
    right: 20px;
    bottom: -20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #9D9BA2;
  }
}

