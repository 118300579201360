@import "@assets/styles/variables.scss";

.sidebar-item {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
  width: 100%;

  a {
    color: $navbar-item-color;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $navbar-item-height;
    border-left: 6px solid transparent;
    position: relative;
    .item-icon {
      opacity: 0.8;
    }
    .tooltip {
      display: block;
      position: absolute;
      white-space: nowrap;
      top: 50%;
      left: 100%;
      transform: translate(8px, -50%);
      color: #fff;
      padding: 8px 16px;
      border-radius: 4px;
      z-index: 100;
      background: #5e5479;
      box-shadow: 0 5px 15px 0 rgba(5, 31, 43, 0.17);
      font-size: 12px;
      font-weight: 700;
    }

    &:hover,
    &:focus {
      background: $navbar-item-active-bg;
      color: $navbar-item-active-color;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      text-decoration: none;
    }
    .item-icon {
      display: flex;
    }
  }

  .active {
    font-weight: 700;
    background: $navbar-item-active-bg;
    color: $navbar-item-active-color;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    text-decoration: none;
    border-left: 6px solid #00ccca;

    .item-icon {
      background: radial-gradient(50% 50% at 50% 50%, rgba(0, 204, 202, 0.24) 0%, rgba(0, 204, 202, 0) 100%);
      opacity: 1;
    }
  }

  &.schema {
    margin: auto 0 0;
  }
}
