@import "@styles/z-index.scss";
@import "@styles/variables.scss";
@import "@styles/mixins.scss";

.dynamic-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-indexes, high2);
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
}

.dynamic-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-indexes, high3);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  padding: 0 5px;
}

.dynamic-modal {
  overflow: hidden;
  background: white;
  z-index: map-get($z-indexes, high1);
  position: relative;
  margin: 1.75rem auto;
  border-radius: 30px;

  .close {
    position: absolute;
    right: 15px;
    top: 10px;
    border: none;
    background: none;
    font-weight: bold;
  }

  &.box {
    padding: 30px 30px 20px 30px;
  }

  &.dynamic-modal_size-normal {
    max-width: 500px;
  }
  &.dynamic-modal_size-extended {
    width: 790px;
  }
  &.dynamic-modal_size-resizable {
    width: auto;
  }
}

.dynamic-modal_dragging {
  opacity: 0.7;
}

.dynamic-modal-resizer {
  position: absolute;

  bottom: 0;
  right: 0;
}

.dynamic-modal-wrapper.dynamic-modal-wrapper_draggable {
  width: 100%;
  height: 0;

  .dynamic-modal {
    margin: 0;
    position: fixed;
    top: 20px;
    left: 60px;
    box-shadow: $table-box-shadow;
    border: 1px solid $gray-color;
    border-radius: $global-border-radius;
    max-height: 90vh;

    .dynamic-modal-content {
      height: calc(100% - 32px);
      overflow-y: auto;
      @include customScrollbar();
    }

    &.box {
      padding: 0;
    }

    .dynamic-modal-drag-panel {
      padding: 2px;
      width: 100%;
      text-align: center;
      background-color: $gray-color;
      cursor: grabbing;

      .dynamic-modal-drag-panel-dot {
        display: inline-block;
        margin: 0 4px;
        width: 7px;
        height: 7px;
        background-color: $light-gray-color;
        border-radius: 50%;
      }
    }
  }
}

.dynamic-modal-resizer {
  border-radius: 100%;
  padding: 11px 5px 11px 3px;
  background-color: $gray-color;
  font-size: 9px;
  color: white;
  font-weight: bold;
  transform-origin: right center;
  transform: translate(0, 50%) rotate(45deg);
  cursor: nwse-resize;
}
