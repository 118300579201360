@import "@styles/common";

.recognition-list-header {
  padding: 20px;
  @include flex(space-between, center);
  max-width: 100%;
  .recognition-list-header-left {
    font-size: $font-size-header;
    font-weight: $font-weight-large;
    color: $secondary;
    @include flex();
    flex-shrink: 0;
    margin-right: 15px;
    .recognition-header-total {
      background: rgba(58, 32, 129, 0.14);
      padding: 5px 10px;
      font-size: $font-size-subtitle;
      font-weight: $font-weight-bold;
      margin-left: 14px;
    }
  }
  .recognition-list-header-right {
    @include flex();
    position: relative;
    .add-button {
      width: 192px;
      margin-left: 16px;
    }
  }
}
