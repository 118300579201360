@import "@assets/styles/mixins.scss";
@import "@assets/styles/variables.scss";

.timer-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  font-size: 18px;
  font-weight: 700;
  color: #3a2081;
}
