@import "@styles/common";

.progress-bar-wrapper {
  position: relative;
  .progress-bar-hidden {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .progress-bar {
    height: 32px;
    width: 100%;
    background: #EFEDF5;
    border-radius: 30px;
    position: relative;
    @include flex(initial, center);
    .point {
      width: 8px;
      height: 8px;
      background: #5E5D98;
      opacity: 0.4;
      border-radius: 10px;
      margin-left: calc(10% - 8px);
    }
    .progress {
      @include flex(right, center);
      position: absolute;
      left: 0;
      top: 0;
      width: 40%;
      min-width: 84px;
      height: 36px;
      background: linear-gradient(180deg, #C2ADFF 0%, #8973C7 100%);
      border: 1px solid #3A2081;
      border-radius: 30px;
      padding: 0 4px;
      .progress-control {
        @include flex(center, center);
        width: 76px;
        height: 28px;
        right: 4px;
        top: 4px;
        background: #FFFFFF;
        border: 1px solid #3A2081;
        border-radius: 30px;
        .percent {
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          color: #3A2081;
          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
