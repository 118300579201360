@import "@assets/styles/variables.scss";

label {
  font-family: $global-font-family;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  font-size: $label-font-size;
  display: flex;
  align-items: center;
  color: $main-text-color;

  &.blue {
    color: $label-dashboard-color;
  }
}
