@import "@styles/common";

.recognition-list-item-wrapper {
  width: 100%;
  @include flex(flex-start, center);
  padding: 8px 32px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background: white;
  margin-top: 24px;
  .recognition-list-item-icon {
    min-width: 96px;
    height: 96px;
    background-color: #3a2081;
    border-radius: 64px 0 64px 64px;
    position: relative;
    @include flex();
    margin-right: 32px;
    .core-value-icon {
      min-height: 61px;
      min-width: 84px;
      background-repeat: no-repeat;
    }
    .core-value-score {
      width: 38px;
      height: 21px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .recognition-list-item-info {
    width: 70%;
    @include flex(space-between, flex-start, column);
    .item-title {
      font-size: 20px;
      font-weight: $font-weight-bold;
      line-height: 32px;
      color: $secondary;
      margin-bottom: 8px;
    }
    .item-description {
      font-size: $font-size-normal;
      line-height: 150%;
      color: $main-color2;
      overflow: hidden;
      max-height: 50px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      word-break: break-all;
    }
  }
  .recognition-created-info {
    font-size: $font-size-subtitle;
    color: #7f7d85;
    line-height: 150%;
    margin-right: 8px;
  }
  .button-wrapper {
    margin-left: auto;
    .action-button {
      width: 44px;
      height: 44px;
      padding: 10px;
      border-radius: 72px;
      background: $border-color;
    }
  }
}
