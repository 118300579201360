@mixin backgroundImage($image) {
  background-image: url($image);
  background-repeat: no-repeat;
  background-position: center;
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin customScrollbar() {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba($color: $gray-color, $alpha: 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-color;
    border-radius: 30px;
  }
}

@mixin flex($justify-content: center, $align-items: center, $flex-direction: row) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
@mixin heightVH($size) {
  height: $size + vh; /* Fallback for browsers that do not support Custom Properties */
  @media (max-width: 630px) {
    height: calc(var(--vh, 1vh) * #{$size});
  }
}
@mixin minHeightVH($size) {
  min-height: $size + vh; /* Fallback for browsers that do not support Custom Properties */
  @media (max-width: 630px) {
    min-height: calc(var(--vh, 1vh) * #{$size});
  }
}
