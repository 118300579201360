@import "@assets/styles/variables.scss";

.checkbox-input-container {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 1px solid $light-gray-color;
  border-radius: $input-radius;
  background-color: $input-bg;

  .checkbox-input {
    display: none;
  }

  .checkbox-input-inner {
    display: none;
    position: absolute;

    height: 22px;
    width: 22px;

    left: 3px;
    top: 3px;

    border-radius: 8px;
    background: $main-color;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 6px;
      width: 12px;
      border-bottom: 2px solid white;
      border-left: 2px solid white;
      transform: translate(-50%, -70%) rotate(-45deg);
    }
  }

  .checkbox-input:checked + .checkbox-input-inner {
    display: block;
  }
  .checkbox-input:disabled + .checkbox-input-inner {
    background: $gray-color;
    cursor: not-allowed;
  }
}
