@import "@styles/common";

.procedure-form {
  padding: 32px;
  label {
    font-weight: 400;
    color: $modal-font-color-secondary;
    opacity: 50%;
    font-size: $font-size-small;
    line-height: 16px;
    margin-bottom: 14px;
  }

  .procedure-form-footer {
    @include flex(space-between, center);
    height: 48px;

    .procedure-form-footer-left {
      font-size: $font-size-header;
      font-weight: 600;
      color: $main-text-color;
    }

    .procedure-form-footer-right {
      @include flex();
      font-size: $font-size-header;
      font-weight: bold;
      color: $main-color;
    }
  }

  .procedure-form-content {
    height: 100%;
    overflow: auto;
    .procedure-name-container {
      .procedure-name-input {
        font-weight: 400;
        font-size: $font-size-normal;
        line-height: 22px;
        background-color: white;
        padding: 0 0 20px 0;
        color: $modal-font-color-secondary;
        border-radius: 0;
        border-bottom: 2px solid $modal-input-border-color;
        &:focus-visible,
        &:focus {
          appearance: none;
          outline: none;
          box-shadow: 0 0 0 0 white;
        }
      }
    }
    .procedure-process-input-wrapper {
      margin-bottom: 23px;
      .procedure-process-input {
        margin-top: 19px;
        border-bottom: 2px solid $modal-input-border-color;
        .rs-picker-toggle-value {
          color: $modal-font-color-secondary !important;
          font-size: $font-size-normal;
        }
        .rs-picker-default .rs-btn,
        .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
          background-color: $modal-input-background-color !important;
          border: none;
          border-radius: 0;
          justify-content: flex-start;
        }
      }
    }
    .teams-wrapper {
      margin-bottom: 23px;
      .teams-body {
        display: grid;
        grid-template-columns: auto auto auto;
      }
      .teams-header {
        font-weight: 400;
        color: $modal-font-color-secondary;
        opacity: 50%;
        font-size: $font-size-small;
        margin-bottom: 14px;
      }
      .team-checkbox {
        accent-color: $accent;
      }
      .team-label {
        margin-left: 3px;
        opacity: 1;
        margin-bottom: 8px;
        .team-label-text {
          margin-left: 8px;
          font-weight: 400;
          line-height: 22px;
          font-size: $font-size-normal;
        }
      }
    }
  }
  .procedure-link-container {
    margin-bottom: 25px;
    .procedure-link-input {
      font-weight: 400;
      font-size: $font-size-normal;
      line-height: 24px;
      background-color: $modal-input-background-color;
      height: 80px;
      max-height: 80px;
      padding: 0 0 40px 10px;
      color: $modal-font-color-secondary;
      border-radius: 0;
      border-bottom: 2px solid $modal-input-border-color;
      &:focus-visible,
      &:focus {
        appearance: none;
        outline: none;
        box-shadow: 0 0 0 0 white;
      }
    }
  }
}
