@import "@styles/variables";

.async-select-picker-input {
  .rs-picker-default .rs-picker-toggle.rs-btn {
    display: flex;
    align-items: center;
  }

  .rs-picker-toggle .rs-btn .rs-btn-default {
    display: flex;
    align-items: center;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 10px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 10px;
  }

  .rs-picker-default {
    width: 100%;
    $input-padding: 10px;

    .rs-btn {
      height: $input-height;
      border-radius: $input-radius;
      padding-top: $input-padding;
      padding-bottom: $input-padding;

      .rs-picker-toggle-clean,
      .rs-picker-toggle-caret {
        top: $input-padding;
      }
    }
  }

  .rs-picker-toggle-value {
    color: $main-text-color !important;
  }

  .rs-picker-default .rs-btn,
  .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
    background-color: #f4f7f9 !important;
    border: none;
  }

  .rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
    padding-right: 44px;
    display: flex;
    align-items: center;
  }
}
