@import "@styles/common";

.user-onboarding-form {
  padding: 32px;
  label {
    font-weight: 400;
    color: $modal-font-color-secondary;
    opacity: 50%;
    font-size: $font-size-small;
    line-height: 16px;
    margin-bottom: 14px;
  }
  .select-picker-input {
    border-bottom: 2px solid $modal-input-border-color;
    margin-bottom: 5px;

    .rs-picker-default {
      width: 100%;
      $input-padding: 10px;

      .rs-btn {
        height: $input-height;
        border-radius: $input-radius;
        padding-top: $input-padding;
        padding-bottom: $input-padding;

        .rs-picker-toggle-clean,
        .rs-picker-toggle-caret {
          top: $input-padding;
        }
      }
    }

    .rs-picker-toggle-value {
      color: $modal-font-color-secondary !important;
      font-size: 16px;
    }

    .rs-picker-default .rs-btn,
    .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
      background-color: $modal-input-background-color !important;
      border: none;
      border-radius: 0;
    }
    .rs-btn-close {
      display: none;
    }
  }

  .form-footer {
    @include flex(flex-end);
    font-size: $font-size-header;
    font-weight: bold;
    color: $main-color;
    margin-top: 20px;
  }
}
