@import "@assets/styles/common";

.table-header {
  @include flex(flex-start);

  position: absolute;
  top: 0;
  width: 100%;
  min-height: 40px;
  color: $table-row-header-color;
  border-radius: $global-border-radius $global-border-radius 0 0;
  border-bottom: 1px solid $table-border-color;
  background: $table-header-bg;
  z-index: 10;

  .table-header-cell {
    height: 100%;
    font-weight: 400;
    font-size: $font-size-small;
    min-height: 40px;
    @include flex(space-between);
    flex-grow: 1;
    width: 100%;
    padding: 10px 25px;
  }

  .export {
    position: absolute;
    right: 30px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .user-details-header {
    font-size: $font-size-subtitle;
  }
}
