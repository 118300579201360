@import "@styles/mixins.scss";
@import "@styles/variables.scss";

.toggle-menu-wrapper {
  position: relative;
  cursor: pointer;

  .toggle-menu-dots-wrapper {
    &.accent {
      display: flex;
      align-items: center;
      justify-content: center;
      @include background-opacity($accent, 0.7);
      border-left: 2px solid $accent;
      padding: 0 12px 0 10px;
      height: 48px;

      .toggle-menu-dots {
        border-radius: 25px;
        border: 2px solid white;
        color: white;
        width: 30px;
        height: 30px;

        .dot {
          background-color: white;
        }
      }
    }

    .toggle-menu-dots {
      @include flex();
      font-weight: bold;
      height: 30px;

      .dot {
        background-color: $secondary;
        width: 4px;
        height: 4px;
        cursor: pointer;
        border-radius: 5px;
        margin: 0 1px;
      }
    }
  }

  .toggle-menu-items {
    position: fixed;
    background: white;
    box-shadow: 0 5px 15px rgba(5, 31, 43, 0.17);
    border-radius: 4px;
    z-index: 100;

    .toggle-menu-item {
      @include flex(flex-start);
      padding: 18px;
      font-weight: 400;
      font-size: 14px;
      height: 56px;
      width: 170px;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-size: contain;
      }
      &:hover {
        background-color: #f6f4fa;
        border-radius: inherit;
      }
      &.dropdown-item-blue {
        color: #5c556f;
      }

      &.dropdown-item-red {
        color: #f07e77;
      }
      &.red-filter {
        .icon {
          filter: brightness(0) saturate(100%) invert(77%) sepia(32%) saturate(3485%) hue-rotate(309deg) brightness(95%)
            contrast(98%);
        }
      }
    }
  }
}
