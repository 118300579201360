@import "@styles/common";

.tab-bar-item {
  .table-wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .table-header {
      border-radius: 0;
    }
  }
}
