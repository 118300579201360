@import "@styles/common";

.empty-wrapper {
  width: 476px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  .icon {
    width: 375px;
    height: 298px;
    cursor: default;
    background-size: contain;
  }
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #5E5B66;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  .subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #7F7D85;
  }
}
