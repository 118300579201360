@import "@styles/common";

.processes-list-wrapper {
  .table-wrapper {
    .table-content {
      margin-top: 41px;
      margin-left: 24px;
      max-height: calc(100% - 50px);
      .parent-wrapper {
        display: flex;
        align-items: center;
        height: 88px;
        .icon {
          width: 20px;
          margin-right: 15px;
          transform: rotate(180deg);
          &.opened {
            transform: rotate(270deg);
          }
        }
        .image-wrapper {
          @include flex();
          width: 56px;
          height: 56px;
          background: rgba(58, 32, 129, 0.2);
          border-radius: 10px;
          margin-right: 16px;
          img {
            width: 42px;
            height: 42px;
          }
        }
        .title-wrapper {
          .title {
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: #373440;
          }
          .counter {
            font-weight: 700;
            font-size: $font-size-subtitle;
            line-height: 24px;
            color: #797979;
          }
        }
      }
      .table-row-wrapper {
        @include customScrollbar();
        overflow-y: auto;
        max-height: 0;
        &.opened {
          max-height: 100%;
          transition: max-height 0.5s ease-in-out;
        }
        .table-row {
          margin-top: 0;
          &:hover {
            border-radius: 0;
          }
        }
      }
    }
  }
}
