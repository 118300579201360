@import "@styles/variables";

.user-form-input-container {
  min-width: 296px;

  &:nth-child(1) {
    margin-right: 32px;
  }
  &:nth-child(3) {
    margin-right: 32px;
  }
  .user-form-input {
    font-weight: 400;
    font-size: $font-size-normal;
    line-height: 22px;
    background-color: white;
    padding: 0 0 20px 0;
    margin-bottom: 19px;
    color: $modal-font-color-secondary;
    border-radius: 0;
    border-bottom: 2px solid $modal-input-border-color;
    &:focus-visible,
    &:focus {
      appearance: none;
      outline: none;
      box-shadow: 0 0 0 0 white;
    }
  }
}
