@import "@assets/styles/variables.scss";

.error-msg-alert {
  display: flex;
  align-items: center;
  // TODO: Use scss var from variables.scss
  border-radius: 10px;
  // TODO: Use scss var from variables.scss
  background-color: #fcebef;
  color: $red-color;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-top: 0;

  .error-msg-alert-icon {
    display: inline-block;
    width: 25px;
    height: 20px;
  }
  .error-msg-alert-content {
    display: inline-block;
    padding-left: 7px;
  }
}