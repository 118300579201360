@import "@styles/common";

.user-meeting-form {
  padding: 32px;
  label {
    font-weight: 400;
    color: $modal-font-color-secondary;
    opacity: 50%;
    font-size: $font-size-small;
    line-height: 16px;
    margin-bottom: 14px;
  }

  .user-meeting-form-content {
    .meeting-title-wrapper {
      margin-bottom: 24px;
      .meeting-title-input {
        font-weight: 400;
        font-size: $font-size-normal;
        line-height: 22px;
        background-color: white;
        padding: 0 0 20px 0;
        color: $modal-font-color-secondary;
        border-radius: 0;
        border-bottom: 2px solid $modal-input-border-color;
        &:focus-visible,
        &:focus {
          appearance: none;
          outline: none;
          box-shadow: 0 0 0 0 white;
        }
      }
    }
    .meeting-date-wrapper {
      margin-bottom: 24px;
      .meeting-date-input {
        font-weight: 400;
        font-size: $font-size-normal;
        line-height: 22px;
        background-color: $modal-input-background-color;
        color: $modal-font-color-secondary;
        border-radius: 0;
        border-bottom: 2px solid $modal-input-border-color;
        &::-webkit-calendar-picker-indicator {
          filter: invert(0.4);
        }
        &:focus-visible,
        &:focus {
          appearance: none;
          outline: none;
          box-shadow: 0 0 0 0 white;
        }
      }
    }
    .meeting-comment-wrapper {
      margin-bottom: 24px;
      position: relative;
      .comment-textarea-length {
        font-weight: 400;
        color: $modal-font-color-secondary;
        opacity: 50%;
        font-size: $font-size-small;
        line-height: 16px;
        position: absolute;
        right: -1px;
      }
      .meeting-comment-input {
        max-width: 624px;
        word-wrap: break-word;
        word-break: break-all;
        height: 92px;
        max-height: 92px;
        text-align: start;
        font-weight: 400;
        font-size: $font-size-normal;
        line-height: 22px;
        padding: 10px;
        background-color: $modal-input-background-color;
        color: $modal-font-color-secondary;
        border-radius: 0;
        border-bottom: 2px solid $modal-input-border-color;
        &::-webkit-calendar-picker-indicator {
          filter: invert(0.4);
        }
        &:focus-visible,
        &:focus {
          appearance: none;
          outline: none;
          box-shadow: 0 0 0 0 white;
        }
      }
    }

    .meeting-next-date-wrapper {
      margin-bottom: 24px;
      display: flex;
      .meeting-next-date-input {
        max-width: 300px;
        font-weight: 400;
        font-size: $font-size-normal;
        line-height: 22px;
        background-color: $modal-input-background-color;
        color: $modal-font-color-secondary;
        border-radius: 0;
        border-bottom: 2px solid $modal-input-border-color;
        &::-webkit-calendar-picker-indicator {
          filter: invert(0.4);
        }
        &:focus-visible,
        &:focus {
          appearance: none;
          outline: none;
          box-shadow: 0 0 0 0 white;
        }
      }
      .add-to-calendar-label {
        opacity: 1;
        .google-calendar-checkbox {
          accent-color: $secondary;
          width: 16px;
          height: 16px;
        }
        .google-calendar-label-text {
          margin-left: 8px;
          font-weight: 400;
          line-height: 22px;
          font-size: $font-size-normal;
          color: $secondary;
        }
      }
    }
    .meeting-manager-select-input-wrapper {
      margin-bottom: 24px;
      .manager-select-input {
        border-bottom: 2px solid $modal-input-border-color;
        .rs-picker-toggle-value {
          color: $modal-font-color-secondary !important;
          font-size: $font-size-normal;
        }
        .rs-picker-default .rs-btn,
        .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
          background-color: $modal-input-background-color !important;
          border: none;
          border-radius: 0;
        }
      }
    }
  }

  .form-footer {
    @include flex(space-between, center);
    font-size: $font-size-header;
    font-weight: bold;
    color: $main-color;
    margin-top: 72px;
    .form-footer-right {
      display: flex;
    }
  }

  .rs-picker-toggle-value {
    color: $modal-font-color-secondary !important;
    font-size: $font-size-normal;
  }
  .rs-picker-default .rs-btn,
  .patient-form .patient-form-content .rs-picker-default .rs-picker-toggle {
    background-color: $modal-input-background-color !important;
    border: none;
    border-radius: 0;
  }
}
