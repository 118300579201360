@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Red+Hat+Display:ital,wght@0,400;0,600;0,700;1,600;1,700;1,900&display=swap");

@import "./items/index.scss";
@import "./variables.scss";
@import "rsuite/dist/rsuite.min.css";
@import "../fonts/redHat.css";

body {
  margin: 0;
  font-family: $global-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .rs-picker-menu {
    z-index: 1111;
    span {
      color: $modal-font-color-secondary !important;
    }
  }

  color: $main-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
