@import "@styles/common";

.button-wrapper {
  button {
    font-family: $global-font-family;
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: $font-size-normal;
    letter-spacing: 1px;
    color: white;
    height: 48px;
    border: none;
    cursor: pointer;
  }

  .full-width {
    width: 100%;
  }

  .padding {
    padding: 0 24px;
  }

  .margin-left {
    margin-left: 16px;
  }

  .margin-right {
    margin-right: 16px;
  }

  .accent {
    @include background-opacity($accent, 0.7);
    border-left: 2px solid $accent;
    color: white;
    padding: 0 16px;
  }

  .grey {
    @include background-opacity($main-color2, 0.7);
    border-left: 2px solid $main-color2;
    color: white;
    padding: 0 16px;
  }

  .secondary {
    @include background-opacity($secondary, 0.35);
    color: white;
  }

  .subtle:not(:disabled) {
    background-color: transparent;
    color: $main-color;
    &:hover,
    &:focus {
      background-color: $light-gray-color;
    }
  }

  .clear {
    background-color: transparent;
    color: $accent;
  }

  .info {
    background-color: transparent;
    color: $main-color;
  }

  .close-btn {
    width: 100px;
    background: #ffffff;
    text-align: center;
    line-height: 21px;
    color: $modal-font-color-primary;
  }

  .preview {
    background: rgba(0, 204, 202, 0.7);
    color: white;
    border-left: 2px solid $primary;
  }

  .outline-blue:not(:disabled) {
    background-color: transparent;
    border: 2px solid $main-color;
    color: $main-color;
    &:hover,
    &:focus {
      background-color: $main-color;
      color: white;
    }
  }

  .outline-dark:not(:disabled) {
    background-color: transparent;
    border: 2px solid $dark-color;
    color: $dark-color;
    &:hover,
    &:focus {
      background-color: $dark-color;
      color: white;
    }
  }

  .google-auth {
    @include flex();
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to left, rgba(51, 0, 255, 0.3), rgba(86, 225, 255, 0.29));
    background-color: white;
    color: rgba(92, 85, 111, 1);
    .icon {
      width: 24px;
      margin-right: 8px;
    }
  }
}
