@import "@assets/styles/mixins.scss";
@import "@assets/styles/variables.scss";

.table-wrapper {
  min-height: 90px;
  margin: 0 20px;
  position: relative;
  overflow: hidden;
  border-radius: $table-border-radius;
  box-shadow: $table-box-shadow;
  &.meetings-table {
    margin: 0;
  }

  .table-clickable {
    .table-row:not(.table-row_disabled) {
      .table-show-onhover {
        display: none;
      }
      .checkbox-show-onhover {
        width: 20px;
        visibility: hidden;
        &.checked {
          visibility: visible;
        }
      }

      cursor: pointer;
      &:hover {
        background-color: $table-row-bg-hover;
        .table-show-onhover {
          display: flex;
        }
        .checkbox-show-onhover {
          visibility: visible;
        }
      }
    }
  }

  &.table-loading {
    &::after {
      content: "";
      display: block;
      background-color: rgba(255, 255, 255, 0.729);
      min-height: 60px;
      margin-top: 61px;
      @include backgroundImage("/assets/images/general/loader.svg");
    }

    &:not(.table-empty)::after {
      position: absolute;
      top: 41px;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: 0;
    }
    &.table-loading-bottom {
      &::after {
        top: auto;
        min-height: 81px;
      }
    }
  }

  .table-content {
    @include customScrollbar();
    overflow-y: auto;
    max-height: calc(100vh - 100px);
  }
}
