@import "@styles/common";

.block-screen {
  @include flex(center, center, column);
  @media (min-width: 1024px) {
    display: none;
  }

  width: 100%;
  height: 100vh;
  background: radial-gradient(183.75% 81.47% at 100% 7.76%, #562081 0%, #3A2081 59.37%);
  .block-screen-logo-wrapper {
    width: 300px;
    height: 300px;
    @include flex();
    @include backgroundImage("/assets/images/general/block-screen-wrapper-logo.svg");
    .block-screen-logo {
      width: 238px;
      height: 238px;
      @include backgroundImage("/assets/images/general/block-screen-logo.svg");
    }
  }
  .block-screen-content {
    color: white;
    max-width: 70%;
    text-align: center;
    margin-top: 26px;
    .block-screen-title {
      font-weight: $font-weight-bold;
      font-size: 20px;
      line-height: 130%;
      margin-bottom: 8px;
    }
    .block-screen-description {
      font-size: 14px;
      line-height: 150%;
      font-weight: 400;
    }
  }
}