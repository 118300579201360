@import "@styles/common";

.date-toggle {
    @include flex(space-between, center);
    background: #FFFFFF;
    padding: 4px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.09));
    width: fit-content;
    min-width: 200px;
    position: absolute;
    right: 24px;
    top: 24px;
    .date-content {
      margin: 8px;
      font-weight: 400;
      font-size: $font-size-small;
      line-height: 16px;
      color: $modal-font-color-primary;
      opacity: 0.5;
    }
    .icon {
      width: 32px;
      height: 32px;
      background-color: rgba(58, 32, 129, .16);
      &.right {
        transform: rotate(180deg);
      }
    }
  }
