@import "@styles/common";

.cell-with-icon {
  @include flex();
  gap: 16px;
  .cell-icon {
    min-width: 40px;
    min-height: 40px;
  }
}
