@import "@styles/common";

.project-form {
  padding: 32px;
  @include customScrollbar();

  .project-form-footer {
    @include flex(space-between, center);
    height: 48px;

    .project-form-footer-left {
      font-size: $font-size-header;
      font-weight: 600;
      color: $main-text-color;
    }

    .project-form-footer-right {
      @include flex();
      font-size: $font-size-header;
      font-weight: bold;
      color: $main-color;
    }
  }

  .project-form-content {
    padding: 4px;
    overflow: auto;
  }

  .average-cost-wrapper {
    .additional-symbol-wrapper {
      right: 30px;
    }
  }

  .work-types-line {
    @include flex(flex-start, stretch, column);

    .work-types-header {
      font-weight: bold;
      font-size: $font-size-subtitle;
      margin-top: 20px;
      color: $main-text-color;
    }

    .work-types-content {
      @include flex(flex-start, stretch, column);

      .work-types-content-items {
        @include flex(flex-start, stretch, column);

        .work-type-row {
          display: flex;
          margin-bottom: 15px;

          .work-type {
            width: 250px;
            margin-right: 15px;
          }

          .actions {
            @include flex();
            .icon {
              height: 20px;
              margin-top: 28px;
              margin-left: 12px;
            }
          }
        }
      }

      .add-work-types-error-row {
        text-align: right;
      }

      .work-types-content-add-item {
        text-align: right;
        color: $accent;
        font-size: $font-size-normal;
        font-weight: bold;
        margin-bottom: 10px;

        span {
          cursor: pointer;
        }
      }
    }
  }
}
