@import "@styles/common";

.comments-wrapper {
  margin-top: 32px;
  .comment {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    .info-wrapper {
      position: relative;
      @include flex(initial, center);
      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius:50%;
        }
      }
      .info {
        .name {
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          color: #000000;
        }
        .date {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #7F7D85;
        }
      }
      .menu-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
    }
    .edit-comment-wrapper {
      .comment-input {
        min-height: 121px;
      }
    }
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #373440;
      opacity: 0.8;
      margin-top: 8px;
      overflow-wrap: anywhere;
    }
  }
}

