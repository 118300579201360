@import "@styles/variables";

.modal-content-wrapper {
  margin: -30px -30px -20px -30px;
  .modal-heading {
    margin: 20px 20px 10px 20px;
    font-weight: 600;
    font-size: 22px;
    color: $main-text-color;
    line-height: 30px;
    margin-bottom: 20px;
    font-family: $global-font-family;
    font-style: normal;
  }
  .modal-content {
    margin: 10px 20px 20px 20px;
    font-family: $global-font-family;
    color: $main-text-color;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  .modal-actions {
    padding: 20px;
    text-align: right;
    background-color: $bg-color;
    .button-wrapper {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
