@import "@styles/common";

.project-list-header {
  padding: 20px;
  @include flex(space-between);
  .project-list-header-left {
    font-size: $font-size-header;
    font-weight: $font-weight-large;
    color: $secondary;
    @include flex();

    .project-total {
      background: rgba(58, 32, 129, 0.14);
      padding: 5px 10px;
      font-size: $font-size-subtitle;
      font-weight: $font-weight-bold;
      margin-left: 14px;
    }
  }

  .project-list-header-right {
    @include flex();
    .filter-btn {
        width: 48px;
        @include flex();
    }

    .project-filter-dropdown {
      background-color: white;
      box-shadow: 0 5px 15px rgba(5, 31, 43, 0.17);
      border-radius: 4px;
      position: absolute;
      right: 206px;
      top: 55px;
      z-index: 101;
      width: 170px;
      .dropdown-label {
        font-weight: 400;
        font-size: $font-size-subtitle;
        line-height: 16px;
        color: $main-color2;
        padding: 16px;
        .filter-radio {
          cursor: pointer;
          accent-color: $main-color2;
        }
        .dropdown-label-text {
          margin-left: 8px;
        }
        &:hover {
          background-color: $contractor-dropdown-bg-color;
        }
      }
      &.closed {
        display: none;
      }
    }

    .new-device-btn {
      margin-left: 20px;
    }
  }
}
