// colors
$primary: #00ccca;
$accent: #ca4cb0;
$secondary: #3a2081;
$gray: #f3f3f5;
$green: #58bb09;
$border-color: #ebe9f2;
$main-color2: #5c556f;
$orange: #f1ae49;
$black: #373440;

// navbar
$navbar-width: 80px;
$navbar-box-shadow: 3px 0px 10px rgba(34, 55, 92, 0.08);
$navbar-bg: radial-gradient(240.73% 76.67% at -76.41% 84.54%, #562081 0%, #3a2081 100%);
$navbar-item-color: #ffffff;
$navbar-item-width: 256px;
$navbar-item-height: 64px;
$navbar-item-active-color: $navbar-item-color;
$navbar-item-active-bg: rgba(0, 204, 202, 0.1);

$global-font-family: "Red Hat Display", sans-serif;

// old
$bg-color: #f4f7f9;
$dark-color: #242f38;
$main-color: $secondary;
$main-text-color: #242f38;
$light-blue-color: #d6e9f9;
$gray-color: #869cac;
$middle-gray-color: #ebeff3;
$light-gray-color: #e7ebee;
$red-color: #e3345e;
$light-red-color: #f9d7df;
$green-color: #35c09f;
$light-green-color: #d7f2ec;

$global-border-radius: 10px;
$global-text-color: $dark-color;
$global-bg-color: #c4ddf4;
$label-font-size: 12px;
$label-dashboard-color: #749acf;

//table
$table-border-radius: $global-border-radius;
$table-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
$table-font-size: 14px;
$table-row-color: #5c556f;
$table-row-header-color: #5c556f;
$table-row-first-child-color: $black;
$table-row-bg-hover: rgba(52, 145, 227, 0.1);
$table-border-color: $light-gray-color;
$table-header-bg: #efedf5;

// input
$input-bg: $bg-color;
$input-radius: $global-border-radius;
$input-height: 40px;

// notification
$error-notification-color: #fe637f;
$success-notification-color: #21cda4;

// status
$active-color-status: $green-color;
$inactive-color-status: $gray-color;

// modal
$modal-font-color-primary: #5c556f;
$modal-font-color-secondary: #3a2081;
$modal-input-border-color: #c4aeda;
$modal-input-background-color: rgba(58, 32, 129, 0.1);

//fonts
$font-weight-large: 900;
$font-weight-bold: 700;
$font-size-header: 26px;
$font-size-subheader: 24px;
$font-size-subtitle: 14px;
$font-size-small: 12px;
$font-size-normal: 16px;

// auth
$auth-font-color: #3a2081;
$auth-input-border-color: #c4aeda;

//details
$details-background-color: #f3f3f5;
$details-color-secondary: rgba(55, 52, 64, 0.5);
$details-items-color: $main-color2;
$details-dark-font-color: #562081;

//procedures list
$table-status-published-color: #1c7f00;
$table-status-published-bg-color: rgba(88, 187, 9, 0.28);
$table-status-unpublished-color: #d48d07;
$table-status-unpublished-bg-color: rgba(255, 184, 0, 0.28);

//procedures list contractor view
$table-status-in-progress-color: #f08200;
$table-status-in-progress-bg-color: #ffeab6;
$table-status-completed-color: #00acaa;
$table-status-completed-bg-color: #cceeee;
$table-status-new-color: $accent;
$table-status-new-bg-color: #f4dbef;
$table-status-reviewed-color: #00acaa;
$table-status-reviewed-bg-color: #cceeee;
$contractor-dropdown-bg-color: #f6f4fa;
