@font-face {
  font-weight: 400;
  font-family: "Red Hat Display";
  font-style: normal;
  src: local("Red Hat Display"), url("RedHatDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-weight: 600;
  font-family: "Red Hat Display";
  font-style: normal;
  src: local("Red Hat Display"), url("RedHatDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-weight: 700;
  font-family: "Red Hat Display";
  font-style: normal;
  src: local("Red Hat Display Bold"), url("RedHatDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-weight: 900;
  font-family: "Red Hat Display";
  font-style: normal;
  src: local("Red Hat Display Extra Bold"), url("RedHatDisplay-ExtraBold.ttf") format("truetype");
}
