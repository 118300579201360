@import "@styles/mixins.scss";
@import "@styles/variables.scss";

.loader-wrapper {
  &.center {
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    .loader {
      width: 40px;
      height: 40px;
    }
  }

  .loader {
    height: 100px;
    text-align: center;
    font-size: 14px;
    color: $main-color;
    @include backgroundImage("/assets/images/general/loader.svg");
  }
}
