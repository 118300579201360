@import "@assets/styles/variables.scss";
@import "@assets/styles/mixins.scss";

.textarea {
  textarea {
    font-family: $global-font-family;
    background: $input-bg;
    border-radius: $input-radius;
    width: 100%;
    border: none;
    padding: 10px;
    color: $main-color;
    resize: none;
    &:focus-visible {
      // TODO: use var from variables.scss
      outline: 3px solid #3491e33f;
    }
    @include customScrollbar();
  }
}