@import "@styles/common";

.label-item {
  @include flex(flex-start, flex-start, column);
  .label-item-title {
    font-size: $font-size-small;
    color: #ADAAB7;
    margin-bottom: 8px;
  }
  .label-item-value {
    font-size: $font-size-normal;
    color: $main-color2;
  }
}