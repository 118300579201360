@import "@styles/common";

.container-item-header {
  @include flex(space-between, center);
  height: 96px;
  padding: 24px 24px;
  font-size: $font-size-header;
  font-weight: $font-weight-large;
  width: 100%;
  background-color: $gray;
  .container-item-header-left {
    width: auto;
    @include flex(flex-start, flex-end);
  }
  .container-item-header-right {
    width: auto;
    height: 100%;
    @include flex(flex-end, flex-end);
    .button-wrapper,.vertical-divider {
      margin-left: 20px;
    }
  }
  .container-item-header-title {
    @include flex(flex-start, center);
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-right: 16px;
    &.clickable {
      cursor: pointer;
    }
    .icon {
      margin-right: 20px;
    }
  }
  .container-item-header-subtitle {
    font-weight: 400;
    font-size: $font-size-subtitle;
    margin-bottom: 4px;
  }
}