@import "@styles/z-index.scss";
@import "@styles/variables";
@import "@styles/mixins.scss";

$wrapper-width: 688px;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-indexes, medium2);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.overlay-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: map-get($z-indexes, medium3);
  width: $wrapper-width;
  max-width: 100vw;
  overflow-y: auto;
  max-height: 100vh;
  background: white;
  border-radius: $global-border-radius;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);

  @include customScrollbar();

  &.overlay-wrapper_size-normal {
    width: $wrapper-width;
  }
  &.overlay-wrapper_size-extended {
    width: 790px;
  }
  &.overlay-wrapper_size-reduced {
    width: 504px;
  }
  &.overlay-wrapper_size-extra_reduced {
    width: 464px;
  }

  .overlay-content {
    height: 96vh;
  }
  .overlay-body {
    position: relative;
    min-height: 100%;
  }

  &.right-position {
    transform: none;
    right: 0;
    top: 0;
    left: auto;
    height: 100%;
    border: none;
    border-radius: unset;
    width: auto;
    .inner-wrapper {
      height: 100%;
    }
  }
}
