@import "@styles/common";

.recognitions-empty-screen-wrapper {
  @include flex(center, center, column);
  .icon {
    margin-top: 48px;
    width: 327px;
    height: 272px;
    @media (max-height: 900px) {
      margin-top: 8px;
    }
  }
  .recognitions-empty-screen-text {
    margin-top: 48px;
    font-weight: $font-weight-bold;
    font-size: 20px;
    line-height: 32px;
    color: $main-color2;
  }
}
